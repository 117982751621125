import { Container } from 'react-bootstrap'




const Module1 = ({moduleData}) => {



    return ( 
        <div className="module-container2 text-black"style={{  backgroundPosition: 'center' }} >
        
           {moduleData && 
                <Container className='d-flex flex-wrap'>
                <div data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom" className='col-lg-12' dangerouslySetInnerHTML={{ __html: moduleData }}>
                </div>
                {/* <div className="col-lg-6 d-flex align-items-center justify-content-end"><img className='img-fluid' src="../marksomay_20221117_1730_DSC0270.jpg" alt="" /></div> */}
                </Container>
                }

        </div>
     );
}
 
export default Module1;