import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { useNavigate} from 'react-router-dom'
import Home from './Home';
import Content from './Content';
import Contactus from './components/Contactus';
import Footer from './components/Footer';
import Navbartest from './components/Navbartest';
import SearchPage from './SearchPage';
import Training from './Training';
import NotFound from './components/NotFound';




function App() {
  // const navigate = useNavigate();

  return (
<div> 
      <Navbartest />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/training/:id" element={<Training />} />
          <Route path='/search/:id' element={<SearchPage />} />
          <Route path=":id" element={<Content />} />
          {/* <Route path="*" element={() => navigate('/') } /> */}
          <Route path="/error/404" element={<NotFound />} />
        </Routes>
        <Contactus />
        <Footer />
      </div>
    </div>
  );
}

export default App;
