import {useState, useEffect, useContext} from "react";
import {Container, Row, Col} from "react-bootstrap";
import Navbar from 'react-bootstrap/Navbar';
import getData from '../services/api';
import {useLocation} from 'react-router-dom';
import {Link} from 'react-router-dom';
import {LanguageContext} from "./LanguageContext";

const Footer = () => {

    const [footer, setFooter] = useState('')
    const location = useLocation();
    const {lang} = useContext(LanguageContext);

    useEffect(() => {
        getData('navigation', lang).then((response) => {

            setFooter(response.data.nav2)
            // console.log(response.data.nav2)
            window.scrollTo(0, 0)
        })
    }, [location, lang])


    return (
        <div className="footer-container">
            <Container className="text-uppercase">
                <Row>
                    <div className="footer-content-main d-flex flex-wrap col-lg-12">
                        <div className="col-lg-3" data-aos="fade-up"
                             data-aos-anchor-placement="top-bottom">
                            <Navbar.Brand className='d-flex me-0 pt-2 pb-2 ' href="/">
                                <img src="../Kegon_Logo_weiss-rgb 1.png" alt=""/>

                            </Navbar.Brand>

                        </div>
                        <div className="footer-content d-flex col-lg-9 justify-content-between text-center">
                            {footer && footer.map((nav) => (
                                <div className='col-xl-2 pt-2 text-center ps-2' key={nav.name} data-aos="fade-up"
                                     data-aos-anchor-placement="top-bottom">
                                    <Link to={nav.url} className='nav-hover  pt-2 text-center'
                                          key={nav.name}>{nav.name}</Link>
                                    {nav.children && nav.children.map((child) => (
                                        <div key={child.name}>
                                            <div className="pt-2">
                                                <Link to={child.url} className='nav-hover pt-2'
                                                      key={child.name}>{child.name}</Link>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            ))}
                            <div className='col-xl-2 pt-2 text-center ps-2' data-aos="fade-up"
                                 data-aos-anchor-placement="top-bottom">
                                <ul className="footer-flag-list mb-0 text-center text-md-start">
                                    <li className='mb-3'>
                                        <a className="flag flag-de external" href="https://kegon.de" target="_blank"
                                           title="">kegon.de</a>
                                    </li>
                                    <li className='mb-2'>
                                        <a className="flag flag-ch external" href="https://kegon.ch" target="_blank"
                                           title="">kegon.ch</a>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-xl-2 pt-2 text-center ps-2' data-aos="fade-up"
                                 data-aos-anchor-placement="top-bottom">
                                <ul className="footer-flag-list mb-0 text-center text-md-start">
                                    <li className='mb-3'>
                                        <a className="flag flag-at external" href="https://kegon.at" target="_blank"
                                           title="">kegon.at</a>
                                    </li>
                                    <li className='mb-2'>
                                        <a className="flag flag-hu external" href="https://kegon.hu" target="_blank"
                                           title="">kegon.hu</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Row>
            </Container>
        </div>
    );
}

export default Footer;
