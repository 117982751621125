import { Container,Row } from "react-bootstrap";
import { useContext } from "react";
import { LanguageContext } from "../LanguageContext";

const Module5 = ({moduleData}) => {
    const { lang } = useContext(LanguageContext);
    console.log(moduleData)
    return ( 
<div className="didyou-container mt-5">
            <Container className="">
                <Row>
                    <div className="col-lg-6 position-relative">
                        <h1 className="display-2 fw-bold mt-5" data-aos="fade-up"
        data-aos-anchor-placement="top-bottom">{lang === "en" ? "Did you know?"  : "Tudtad?"}</h1>
                    </div>
                  
                    
                    <div className="col-lg-6 d-flex align-items-end">
                        
            <div className="didyou-p fs-5" dangerouslySetInnerHTML={{ __html: moduleData[0].name }} data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"></div>
            {/* {lang === "en" ? "AGILE:"  : "AGILIS:"} */}
                    </div>
                   </Row>  
                   <Row className="mb-5" data-aos="fade-up"
        data-aos-anchor-placement="top-bottom">
                   <div className="col-lg-6"></div>
                    <div className="col-lg-6">
                        
                            <div className="fs-5" dangerouslySetInnerHTML={{ __html: moduleData[0].text }}></div>
                        {/* {lang === "en" ? "All of our trainings Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Maecenas ultricies lacus vel congue ultricies. Pellentesque euismod neque felis, eu gravida mi tempor a."  : "Magyar szovegblablabla"} */}
                        
                        
                    </div>
                   </Row>
                   <img className="didpicture img-fluid" style={{maxWidth:'150px'}} src="../question.png" alt=""  data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"/>
            </Container>
        </div>
     );
}
 
export default Module5;
