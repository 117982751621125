import { Row } from "react-bootstrap";

const Module10 = ({moduleData}) => {
    return ( 
        <div className="scaled-container   text-dark " >
        <Row className="d-flex justify-content-center mb-2 mt-4">
            <div className=" scaled-container-top col-lg-3  " data-aos="fade-up" data-aos-delay="800"
    data-aos-anchor-placement="top-bottom">
            <div className="d-flex justify-content-center text-center" dangerouslySetInnerHTML={{ __html: moduleData[0].text }}></div>
        </div>
        </Row>
        <Row className="d-flex">
        <div className=" scaled-container-center d-flex flex-wrap justify-content-evenly ">
            <div className=" col-lg-3 scaled-container-center-left d-flex justify-content-center"  data-aos="fade-up" data-aos-delay="1200"
    data-aos-anchor-placement="top-bottom">
                <div className="text-center" dangerouslySetInnerHTML={{ __html: moduleData[1].text }}></div>
            </div>
            <div className="col-lg-1 scaled-container-center-center d-flex align-items-center justify-content-center"  data-aos="fade-up" data-aos-delay="300"
    data-aos-anchor-placement="top-bottom">
                <img style={{width:'200px'}} src="../Group.png" alt="" />
            </div>
            <div className="col-lg-3 scaled-container-center-right"  data-aos="fade-up" data-aos-delay="1600"
    data-aos-anchor-placement="top-bottom">
                <div className="text-center" dangerouslySetInnerHTML={{ __html: moduleData[2].text }}></div>
            </div>
        </div>
        </Row>
        <Row className="d-flex justify-content-center mt-2 mb-4">
        <div className=" col-lg-3 scaled-container-bottom d-flex justify-content-center" data-aos="fade-up" data-aos-delay="2200"
    data-aos-anchor-placement="top-bottom">
            <div className="text-center" dangerouslySetInnerHTML={{ __html: moduleData[3].text }}></div>
        </div>
        </Row>
    </div>
     );
}
 
export default Module10;
