import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Container, Row, Col } from "react-bootstrap";
import { LanguageContext } from "../LanguageContext";
import { useContext } from 'react';

const Module4 = ({moduleData}) => {
    const { lang, updateLang } = useContext(LanguageContext);


    return (
        <div className="ourcustomer-container text-black">
           <Container >
                <div >
                </div>
            </Container>
            <Swiper data-aos="fade-up"
                data-aos-anchor-placement="top-bottom" className="swiper-container swiper-container-free-mode"
                // install Swiper modules
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={0}
                slidesPerView={4}
                loop={true}
                speed={8000}
                autoplay={{

                    pauseOnMouseEnter: true,
                    disableOnInteraction: true,
                    reverseDirection: false,

                }}
                navigation
                breakpoints={{
                    375: {

                        slidesPerView: 1,

                    },
                    // when window width is >= 768px
                    768: {

                        slidesPerView: 3,
                    },
                    1280: {
                        slidesPerView: 4
                    }
                }}

                effect="fade"
            >
                <div className="swiper-wapper">

                    {moduleData &&
                        moduleData.map((customer) => (
                            <SwiperSlide className="swiper-slide" key={`${customer.name}`} >
                                <img
                                    style={{maxHeight:'80px', maxWidth:'350px'}}
                                    className="d-block mx-auto img-fluid  p-2"
                                    src={`${customer.image}`}
                                    alt={`${customer.name}`}
                                    key={`${customer.name}`}
                                /></SwiperSlide>
                        ))}


                </div>
            </Swiper>




        </div>
     );
}

export default Module4;
