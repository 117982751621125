import {useState } from "react";
import { Link } from 'react-router-dom';
import { ChevronDown } from "react-bootstrap-icons";

const NavBarContent = (nav) => {

    const [expand, setExpand] = useState(true);


    return (
        <nav>
<div className="dropdownmain">
            <ul className=' text-center ps-5 ' key={nav.nav.name}   >
                <li className=" "><Link to={nav.nav.url} className='  d-flex  pt-2 ' key={nav.nav.name} >{nav.nav.name}  {nav.nav.children.length > 0  && <div><ChevronDown className="ps-1" style={{fontSize:'0.8rem'}}/></div> }  </Link>
               
                {nav.nav.children.length > 0 && <ul className="flex-wrap  ">
                  <div className="dropdown-contentmain">   
               { expand && (nav.nav.children && nav.nav.children.map((child) => (
                    <li className='  bg-white p-1 ' key={child.name} >
                       
                        <div className="dropdown-testmain">
                        <Link to={child.url} className='nav-bar-final pt-1' key={child.name} >{child.name}</Link>
                        </div>
                        
                    </li>
                )))}
                </div>
                </ul>}
                
                </li>
                
            </ul>
</div>

        </nav>
    );
}

export default NavBarContent;