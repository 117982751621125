import {useState } from "react";
import Calendarboxcontent from "../Calendarboxcontent";
const Module6 = ({moduleData}) => {
    const [calendarbox,setCalendarbox] = useState([])
    
    return ( 
        <div className=" modul-container mt-5 mb-5 ">

        {moduleData && moduleData.map((data)=>{
            <div>{data.date}
        
            <ul>
                {data.trainings.map((item)=>{
                    <li>
                        <span>{item.name}
                    
                        </span>
                    </li>
                })}
            </ul>
            </div>
        })}
        
        
                    <Calendarboxcontent moduleData={moduleData} />
                </div>
      
     );
}
 
export default Module6;
