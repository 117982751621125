import { Container, Row } from "react-bootstrap";
import { useContext } from "react";
import { LanguageContext } from "../LanguageContext";

const Module7 = ({ moduleData }) => {

  return (
    <div
      className="safe-container bg-white "
      style={{
        backgroundImage: "url(../Rectangle_18grey.png)",
        backgroundSize: "cover",
      }}
    >
      <Container className="text-white">
        <Row>
          <div
            className="col"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            <h1 className="display-2 text-white mt-5 pt-5">
              {moduleData[0].name}
            </h1>
          </div>
        </Row>
        <Row>
          <div
            className="col-lg-7 fs-5 mt-5 mb-5"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            dangerouslySetInnerHTML={{ __html: moduleData[0].text }}
          ></div>
          <div
            className="col-lg-7"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          ></div>

          {/* <div className="col-lg-5 d-flex align-items-end justify-content-center">
            <img
              style={{ height: "150px" }}
              className=" bottom-0 end-0 "
              src="../Group.png"
              alt=""
            />
          </div> */}
        </Row>
        {/* <a href={lang === "en" ? "/why-safe" : "/miert-safe"}>
          <button
            className="button-book mt-5 mb-5"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            {lang === "en" ? "KNOW MORE" : "TUDJ MEG TÖBBET"}
          </button>
        </a> */}
      </Container>
    </div>
  );
};

export default Module7;
