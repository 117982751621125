import { Container, Row, Col } from 'react-bootstrap'
const Module1 = ({moduleData}) => {

    return (
        <div className="module-container"style={{ backgroundImage: 'url(./secpic.png)', backgroundSize:'cover' }} >

           {moduleData &&
                <Container>
                <div data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom" className='col-lg-12' dangerouslySetInnerHTML={{ __html: moduleData }}>
                </div>
                </Container>
                }

        </div>
     );
}

export default Module1;
