import { Container, Row } from "react-bootstrap";
import { useContext, useState, useEffect } from "react";
import { LanguageContext } from "../../LanguageContext";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

const Reszletek = ({ trainingData }) => {
  const [actualData, setActualData] = useState({});
  const [show, setShow] = useState(false);
  const Completionist = () => <span>BOOK DONE</span>;
  const handleClose = () => setShow(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [position, setPosition] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const [isAccepted, setIsAccepted] = useState(false);
  const [contactData, setContactData] = useState("");
  const [firstNameIsInvalid, setFirstNameIsInvalid] = useState(false);
  const [lastNameIsInvalid, setLastNameIsInvalid] = useState(false);
  const [companyNameIsInvalid, setCompanyNameIsInvalid] = useState(false);
  const [positionIsInvalid, setPositionIsInvalid] = useState(false);
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [phoneIsInvalid, setPhoneIsInvalid] = useState(false);
  const [messageIsInvalid, setMessageIsInvalid] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);
  const [submitSuccessful, setSubmitSuccessful] = useState(false);
  const { lang, updateLang } = useContext(LanguageContext);
  const [error, setError] = useState("");
  const handleChange = (event) => {
    setIsAccepted(event.target.checked);
  };

  const handleShow = (data) => {
    setShow(true);
    setActualData(data);
  };
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date().getTime();
      const countdownDate = new Date(trainingData.start).getTime();
      const timeDifference = countdownDate - now;

      setTimeLeft({
        days: Math.floor(timeDifference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((timeDifference % (1000 * 60)) / 1000),
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, [trainingData]);

  const milliseconds = Date.parse(trainingData.start);
  // console.log(milliseconds,'seconds');

  const date = new Date(milliseconds);
  const seconds = date.getTime() / 304;

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          {days} DAYS / {hours} HOURS / {minutes} MiNUTES / {seconds} SEC{" "}
        </span>
      );
    }
  };

  const emailRegex = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const isValid = () => {
    if (firstName.length < 2) {
      setFirstNameIsInvalid(true);
    } else {
      setFirstNameIsInvalid(false);
    }
    if (lastName.length < 2) {
      setLastNameIsInvalid(true);
    } else {
      setLastNameIsInvalid(false);
    }
    if (companyName.length < 2) {
      setCompanyNameIsInvalid(true);
    } else {
      setCompanyNameIsInvalid(false);
    }
    if (position.length < 2) {
      setPositionIsInvalid(true);
    } else {
      setPositionIsInvalid(false);
    }
    if (phone.length < 6) {
      setPhoneIsInvalid(true);
    } else {
      setPhoneIsInvalid(false);
    }

    if (!emailRegex.test(email)) {
      setEmailIsInvalid(true);
    } else {
      setEmailIsInvalid(false);
    }
    if (message.length < 2 || message.length > 100) {
      setMessageIsInvalid(true);
    } else {
      setMessageIsInvalid(false);
    }
    if (
      firstName.length > 1 &&
      lastName.length > 1 &&
      emailRegex.test(email) &&
      message.length < 101
    ) {
      return true;
    }
    return false;
  };

  const trainingId = trainingData.id;

  const handleSubmit = () => {
    axios.defaults.withCredentials = true;
    if (isValid()) {
      axios
        .get(`https://api.kegon.pl/sanctum/csrf-cookie`, {})
        .then(() =>
          axios.post("https://api.kegon.pl/api/booking", {
            id: trainingId,
            lastname: lastName,
            firstname: firstName,
            companyName: companyName,
            position: position,
            email: email,
            phone: phone,
            message: message,
            privacy: isAccepted,
            terms: isAccepted,
          })
        )
        .then((res) => {
          setFirstName("");
          setLastName("");
          setEmail("");
          setPhone("");
          setMessage("");
          setCompanyName("");
          setPosition("");
          setFirstNameIsInvalid(false);
          setLastNameIsInvalid(false);
          setEmailIsInvalid(false);
          setPositionIsInvalid(false);
          setMessageIsInvalid(false);
          setIsAccepted(false);
          setCompanyNameIsInvalid(false);
          setPositionIsInvalid(false);

          setSubmitSuccessful(true);
          setSubmitFailed(false);
          setTimeout(() => {
            setSubmitSuccessful(false);
          }, 3000);
        })
        .catch((error) => {
          setError(error.response.status);
          setSubmitFailed(true);
        });
    } else {
      setSubmitFailed(true);
    }
  };

  return (
    <div className="reszletek-container mt-5 ">
      <Container>
        <Row>
          <div
            className="reszletek-left col-lg-4  d-flex justify-content-center"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            {trainingData && (
              <p className="mt-5 fw-bold">
                {trainingData.start.substring(0, 10)} -{" "}
                {trainingData.end.substring(8, 10)}
              </p>
            )}
            {/* <p className="mt-5 fw-bold">{trainingData.start}-{trainingData.end}</p> */}
          </div>
          <div
            className="reszletek-center col-lg-4   d-flex justify-content-center"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            <p className="mt-5 fw-bold">{trainingData.type}</p>
          </div>
          <div
            className="reszletek-right col-lg-4 d-flex  justify-content-center"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            <p className="mt-5 fw-bold">SAFe Certificate</p>
          </div>
        </Row>
        <Row>
          <div
            className="training-container col-lg-5 mt-5"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            <h1 className=" display-2 mb-5 fw-bold">
              {lang === "en" ? "Details" : "SZCZEGÓŁY"}
            </h1>
          </div>
        </Row>
        <div className="d-flex flex-wrap">
          {trainingData && (
            <div
              className="reszletek-text col-lg-12 fs-5"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
              dangerouslySetInnerHTML={{ __html: trainingData.text }}
            ></div>
          )}
          {/* <div className="col-lg-7">
            <img
              className="img-fluid"
              src="../marksomay_20221117_1732_DSC0315.jpg"
              alt=""
            />
          </div> */}
        </div>
      </Container>
      <div className="reszletek-footer">
        <Container>
          <Row className=" d-flex reszletek-footer mt-5 pt-4 pb-4">
            <div className="d-flex col-lg-3">
                <a href= {lang === "en" ? "/calendar" : "/kalendarz"}>
                <button
                  className="button-book"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-bottom "
                >
                  {lang === "en" ? "BOOK NOW" : "Zarezerwuj teraz"}
                </button>
             </a>
            </div>

            <div
              className="col-lg-6 d-flex align-items-center"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
            >
              {timeLeft.days <= 0 &&
              timeLeft.hours <= 0 &&
              timeLeft.minutes <= 0 &&
              timeLeft.seconds <= 0 ? (
                <p>
                  {lang === "en" ? "Book has ended" : "Książka się skończyła"}
                </p>
              ) : (

                <span>

                  {timeLeft.days} {lang === "en" ? "DAYS" : "dni"} / {timeLeft.hours}  {lang === "en" ? "HOURS" : "godzin"} / {" "}
                  {timeLeft.minutes}  {lang === "en" ? "MINUTES" : "minut"} / {timeLeft.seconds}  {lang === "en" ? "SECONDS" : "sekund"}
                </span>
              )}
              {/* {       <span>
        {timeLeft.days} DAYS /  {timeLeft.hours} HOURS /  {timeLeft.minutes} MINUTES / {' '}
        {timeLeft.seconds} SECONDS
      </span>} */}
              {/* <p className="">10 DAYS / 6 HOURS / 22 MINUTES / 06 SEC  </p> */}
            </div>
          </Row>

          <Modal
            className="d-flex align-items-center justify-content-center text-white "
            show={show}
            onHide={handleClose}
          >
            <Modal.Header
              className="d-flex justify-content-center "
              closeButton
              style={{ backgroundImage: "url(../contactus.jpg)" }}
            >
              <div className="d-flex justify-content-center ">
                <Modal.Title className="text-center ps-5">
                  {trainingData.name}
                </Modal.Title>
                <Modal.Title className="" hidden>
                  {trainingData.id}
                </Modal.Title>
              </div>
            </Modal.Header>
            <Container
              className="modal-contact-container"
              style={{ backgroundImage: "url(../contactus.jpg)" }}
            >
                                {submitSuccessful ? (
                  <div className="alert alert-success">
                    {lang === "en"
                      ? "Your message has been sent!"
                      : "Twoja wiadomość została wysłana!"}
                  </div>
                ) : (
                  error && (
                    <div className="alert alert-danger">
                      {lang === "en"
                        ? "Something went wrong"
                        : "Coś poszło nie tak"}
                    </div>
                  )
                )}
              <Container className="mt-4 ">
                <Row>
                  <div className="col">
                    <div className="row g-3">
                      <div className="col-lg-6">
                        {lastNameIsInvalid && (
                          <small style={{ color: "red" }}>
                            {lang === "en"
                              ? "*At least two characters"
                              : "* co najmniej dwa znaki"}
                          </small>
                        )}
                        <input
                          type="text"
                          className="form-control border-bottom rounded-0 p-0 text-white pb-3 "
                          placeholder={
                            lang === "en" ? "Last Name*" : "Nazwisko*"
                          }
                          aria-label="Last name"
                          value={lastName}
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-lg-6">
                        {firstNameIsInvalid && (
                          <small style={{ color: "red" }}>
                            {lang === "en"
                              ? "*At least two characters"
                              : "* co najmniej dwa znaki"}
                          </small>
                        )}
                        <input
                          type="text"
                          className="form-control border-bottom rounded-0 p-0 text-white pb-3"
                          placeholder={
                            lang === "en" ? "First Name*" : "Imię*"
                          }
                          aria-label="First name"
                          value={firstName}
                          onChange={(e) => {
                            setFirstName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Row>
              </Container>
              <Container>
                <Row>
                  <div className="col">
                    <div className="row g-3">
                      <div className="col-lg-6 pt-3">
                        {companyNameIsInvalid && (
                          <small style={{ color: "red" }}>
                            {lang === "en"
                              ? "*At least two characters"
                              : "* co najmniej dwa znaki"}
                          </small>
                        )}
                        <input
                          type="text"
                          className="form-control border-bottom rounded-0 p-0 text-white pb-3"
                          placeholder={
                            lang === "en" ? "Company Name*" : "Nazwa firmy*"
                          }
                          aria-label="Company name"
                          value={companyName}
                          onChange={(e) => {
                            setCompanyName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-lg-6 pt-3">
                        {positionIsInvalid && (
                          <small style={{ color: "red" }}>
                            {lang === "en"
                              ? "*At least two characters"
                              : "* co najmniej dwa znaki"}
                          </small>
                        )}
                        <input
                          type="text"
                          className="form-control border-bottom rounded-0 p-0 text-white pb-3"
                          placeholder={lang === "en" ? "Position" : "Stanowisko"}
                          aria-label="Position"
                          value={position}
                          onChange={(e) => {
                            setPosition(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Row>
              </Container>
              <Container>
                <Row>
                  <div className="col">
                    <div className="row g-3">
                      <div className="col-lg-6 pt-3">
                        {emailIsInvalid && (
                          <small style={{ color: "red" }}>
                            {lang === "en"
                              ? "*Wrong e-mail format"
                              : "*Niewłaściwy format wiadomości e-mail"}
                          </small>
                        )}
                        <input
                          type="email"
                          className="form-control border-bottom rounded-0 p-0 text-white pb-3"
                          placeholder={lang === "en" ? "E-mail*" : "E-mail*"}
                          aria-label="E-mail*"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-6 pt-3">
                        {phoneIsInvalid && (
                          <small style={{ color: "red" }}>
                            {lang === "en"
                              ? "*Minimum 6 maximum 20 number"
                              : "*Minimum 6 maksymalnie 20 numerów"}
                          </small>
                        )}
                        <input
                          type="number"
                          className="form-control border-bottom rounded-0 p-0 text-white pb-3"
                          placeholder={
                            lang === "en" ? "Phone number*" : "Numer telefonu*"
                          }
                          aria-label="Phone number*"
                          value={phone}
                          onChange={(e) => {
                            setPhone(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 mt-2">
                    {messageIsInvalid && (
                      <small style={{ color: "red" }}>
                        {lang === "en"
                          ? "*At least 2, maximum 100 character"
                          : "*Co najmniej 2, maksymalnie 100 znaków"}
                      </small>
                    )}
                    <textarea
                      type="text"
                      className="form-control border-bottom rounded-0 p-0 text-white pb-3 mt-2 "
                      rows={3}
                      placeholder={lang === "en" ? "Message" : "Wiadomość"}
                      aria-label="Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                </Row>
              </Container>
              <Container>
                <div className="contact-us-understand  col-lg-12">
                  <div className="d-flex flex-row align-items-start">
                    <input
                      type="checkbox"
                      value="1"
                      name="group1"
                      className="contact-us-input mt-2 me-3 "
                      onChange={handleChange}
                      onClick={(e) => setIsAccepted(e.target.checked)}
                    />

                    <p className="">
                      {lang === "en"
                        ? "I understand and i accept the "
                        : "Megértem és elfogadom az "}
                      <a className="ps-1" href={lang === "en" ? "https://api.kegon.pl/uploads/files/adatvedelemi_tajekoztato.pdf"  : "https://api.kegon.pl/uploads/files/adatvedelemi_tajekoztato.pdf"}>
                        {lang === "en"
                          ? "Privacy policy"
                          : "Adatvédelmi szabályzatot "}
                      </a>{" "}
                    </p>
                  </div>
                  <div className="d-flex flex-row align-items-start">
                    <input
                      type="checkbox"
                      value="1"
                      name="group1"
                      className="mt-2 me-3"
                      onChange={handleChange}
                      onClick={(e) => setIsAccepted(e.target.checked)}
                    />
                    <p className="">
                      {" "}
                      {lang === "en"
                        ? "I understand and i accept the "
                        : "Megértem és elfogadom az "}
                      <a className="ps-1" href={lang === "en" ? "https://api.kegon.pl/uploads/files/Adatkezelési tájékoztató_230322_ENG.pdf"  : "https://api.kegon.pl/uploads/files/Adatkezelési tájékoztató_230322_HU.pdf"}>
                        {lang === "en"
                          ? "Terms and Conditions"
                          : "Általános Szerződési Feltételeket "}{" "}
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </Container>

              <Container>
                <Row>
                  <div className="col-lg-4 pt-2 mt-5">
                    <button
                      className="button-book2 mb-5"
                      onClick={() => handleSubmit()}
                    >
                      {lang === "en" ? "SEND" : "WYŚLIJ"}
                    </button>
                  </div>
                </Row>

              </Container>
            </Container>
          </Modal>
        </Container>
      </div>
    </div>
  );
};

export default Reszletek;
