import { useEffect, useState, useContext } from 'react'
import { useLocation } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Search} from 'react-bootstrap-icons'
import { ChevronRight } from 'react-bootstrap-icons'
import { Container, Row, Col } from 'react-bootstrap'
import GetData from '../services/api';
import { Link, useNavigate } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
import { LanguageContext } from "./LanguageContext";
import NavBarContent from './Navbarcontent';

function Navbartest() {

    const [search, setSearch] = useState(false)
    const [searchNav, setSearchNav] = useState(false)
    const [navBar, setNavBar] = useState('')
    const [navbarbox,setNavbarbox] = useState([])
    const [expanded, setExpanded] = useState(false);
    const location = useLocation();
    const [title, setTitle] = useState('')
    const [searchValue, setSearchValue] = useState("");
    const { lang, updateLang } = useContext(LanguageContext);
    const [navBarHeader,setNavBarHeader]=useState('')
    const navigate = useNavigate();

    useEffect(() => {
        GetData('navigation', lang).then((response) => {
            setNavbarbox(response.data.nav1)
            setNavBar(response.data.nav1)
            setNavBarHeader(response.data.nav0)
            window.scrollTo(0, 0)
        })
    }, [location, lang])



    const handleLangChange = (newLang) => {
        updateLang(newLang);
        navigate('/')
    };

    useEffect(() => {
        GetData('config', lang).then((response) => {
            setTitle(response.data)
        })
    }, [lang])

    const handleSearchSubmit = () => {
        navigate("search/" + searchValue);
        setSearchValue("");
        setSearchNav(false)
    };

    return (
        <div>

            <Navbar className="navbar-main" bg="white" expand="*" fixed="top" expanded={expanded}>

                <Container fluid>
                    <Navbar.Brand className='d-flex me-0 col-lg-2' href="/" >
                        <img className='img-fluid ps-2' src="../KEGON_Logo.png" alt="" />
                    </Navbar.Brand>

                    {/* <div>
                        <select id='langbox' className='mini-box  ps-2 border-0' onChange={(e) => handleLangChange(e.target.value)}>

                            <option className='langbox' value="pl">HU</option>
                            <option value="fr">FR</option>
                            <option value="en">EN</option>
                        </select>
                    </div>

                    {lang === "en" ? "Hello" : lang === "fr" ? "Bonjour" : "Szia"} */}
                    <div className='col-lg-5 d-none d-lg-block'>
                    <div className='col-lg-12 d-flex align-items-center dropdown' >



   {/* {navBarHeader && navBarHeader.map((nav) => (
                                        <div className='col-lg-4 text-center ' onMouseOver={() => {
                                            setMoreOur(!moreOur);}} key={nav.name} >
                                            <Link to={nav.url} className='nav-hover2  ' key={nav.name} onClick={() => setExpanded(false)}>{nav.name}</Link>
                                         {moreOur &&  ( nav.children && nav.children.map((child) => (
                                                <div className='' key={child.name}>
                                                    <Link to={child.url} className='nav-hover   pt-1' key={child.name} onClick={() => setExpanded(false)}>{child.name}</Link>
                                                </div>
                                            )))}
                                        </div>
                                    ))}
                                    {console.log(navBar)} */}
                                       {navBarHeader && navBarHeader.map((nav) =><NavBarContent  key={nav.name} nav={nav}  setExpanded={setExpanded} /> )}
</div>
</div>
                    <div className=' col-lg-4  d-flex  d-none d-md-block'>
                        <Col className=' col-lg-12 d-flex  justify-content-end '>

                            {searchNav && (

                                <div className="searchnav col-lg-5" data-aos="fade-left">
                                    <div className=" d-flex justify-content-around me-5 input-group input-group-sm ">
                                        <div className="mini-box " style={{cursor:'pointer'}}>
                                            <Search className='d-flex align-items-center  ' />
                                        </div>

                                        <input
                                            type="text"
                                            className=" form-control"
                                            aria-label="Sizing example input"
                                            aria-describedby="inputGroup-sizing-sm"
                                            placeholder={lang === "en" ? "Please enter the search term:"  : "Wprowadź wyszukiwane hasło:"}
                                            value={searchValue}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    handleSearchSubmit();
                                                    // setExpanded(expanded ? false : "expanded")
                                                }
                                            }}
                                            onChange={(e) => {
                                                setSearchValue(e.target.value);
                                            }}
                                        >

                                        </input>

                                    </div>
                                </div>
                            )}



                            <div style={{cursor:'pointer'}} className="mini-box me-1" onClick={() => {
                                setSearchNav(!searchNav);
                            }}>
                                <Search color='white' />
                            </div>


                            <div className=" me-1"> <SocialIcon url={title.facebook} target="_blank" network="facebook" bgColor="#F6A200" style={{ height: 40, width: 40 }} />

                            </div>


                            <div className=" me-1"> <SocialIcon url={title.linkedin} target="_blank" network="linkedin" bgColor="#F6A200" style={{ height: 40, width: 40 }} />

                            </div>
                            <div className="dropdown">
  <div className="mini-box">
    <div className="dropdown-lang">
      <span onClick={(e) => handleLangChange(lang === "pl" ? "en" : "pl")} value={lang === "pl" ? "en" : "pl"} href="">
        {lang === "pl" ? "PL " : "EN "}
      </span>
    </div>
  </div>
  <div className="dropdown-content">
    <div className="mini-box mb-2">
      <div className="dropdown-lang">
        <span onClick={() => handleLangChange(lang === "pl" ? "en" : "pl")} value={lang === "pl" ? "en" : "pl"} href="">
          {lang === "pl" ? "EN " : "PL "}
        </span>
      </div>
    </div>
  </div>
</div>

                        </Col>
                    </div>



                    <Navbar.Toggle aria-controls="navbarScroll" className='border-0 p-0 d-flex align-items-center pe-2 ' onClick={() => setExpanded(expanded ? false : "expanded")} />


                    <Navbar.Collapse id="navbarScroll" className='' >
                        <Nav
                            className="me-auto my-2 my-lg-0 "
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >

                            <Container>
                                <Row>
                                    <Col className='d-flex'>
                                    </Col>
                                </Row>
                            </Container>

                        </Nav>

                        <div className=" toggle-menu-bar d-flex justify-content-around flex-wrap pb-3 mw-50" >
                            <Container className=''>
                                <Container className='d-flex justify-content-center pb-3 d-block d-md-none'>
                                    <Row className=''>
<div className='d-flex '>

                                        <div style={{cursor:'pointer'}} className=' col-lg-3 pe-1' onClick={() => {
                                            setSearch(!search);
                                        }}>
                                            <div  className="mini-box">
                                                <Search color='white' />
                                            </div>
                                        </div>
                                        <div className='col-lg-3 pe-1'>
                                            <div className=""> <SocialIcon url={title.facebook} target="_blank"  network="facebook" bgColor="#F6A200" style={{ height: 40, width: 40 }} />

                                            </div>
                                        </div>
                                        <div className='col-lg-3 pe-1'>
                                            <div className=""> <SocialIcon url={title.linkedin} target="_blank" network="linkedin" bgColor="#F6A200" style={{ height: 40, width: 40 }} />

                                            </div>
                                        </div>
                                        <div className='col-lg-3 pe-1'>
                                            <div className="dropdown">


                                                <div className="mini-box" onClick={() => setExpanded(expanded ? false : "expanded")}>
                                                <div className="dropdown-lang">
      <span onClick={(e) => handleLangChange(lang === "pl" ? "en" : "pl")} value={lang === "pl" ? "en" : "pl"} href="">
        {lang === "pl" ? "PL " : "EN "}
      </span>
    </div></div>
                                                </div>
                                                <div className="dropdown-content">
                                                    <div className="mini-box mb-2" onClick={() => setExpanded(expanded ? false : "expanded")}>
                                                    <div className="dropdown-lang">
        <span onClick={() => handleLangChange(lang === "pl" ? "en" : "pl")} value={lang === "pl" ? "en" : "pl"} href="">
          {lang === "pl" ? "EN " : "PL "}
        </span>
      </div>
                                                    </div>
                                                    {/* <div className="mini-box">
                                                        <div className="dropdown-lang">
                                                            <Nav.Link href="">PL</Nav.Link>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                </Container>
                                {search && (
                                    <div className="input-group input-group-sm mb-3" data-aos="zoom-in">
                                        <div className="mini-box">
                                            <Search className='d-flex align-items-center  ' />
                                        </div>

                                        <input
                                            type="text"
                                            className="form-control"
                                            aria-label="Sizing example input"
                                            aria-describedby="inputGroup-sizing-sm"
                                            placeholder={lang === "en" ? "Please enter the search term:"  : "Wprowadź wyszukiwane hasło:"}
                                            value={searchValue}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    handleSearchSubmit();
                                                    setExpanded(expanded ? false : "expanded")
                                                }
                                            }}
                                            onChange={(e) => {
                                                setSearchValue(e.target.value);
                                            }}
                                        ></input>

                                    </div>
                                )}

                                <Row className='d-flex '>


                                {navBar && navBar.map((nav) => (
                                    <div className='hamburger-navbar col-lg-3' key={nav.name}>
                                        <div className='pb-3 pt-2'>
                                        <Link to={nav.url}  className='nav-hover fw-bold pt-2' key={nav.name} onClick={() => setExpanded(false)}>{nav.name}</Link>
                                        </div>
                                        {nav.children && nav.children.map((child) => (
                                            <div key={child.name} className="pb-2">
                                                <Link to={child.url} className='nav-hover pt-2 pb-1' key={child.name} onClick={() => setExpanded(false)}><ChevronRight style={{fontSize:'0.7rem'}}/> {child.name}</Link>
                                            </div>
                                        ))}
                                    </div>
                                ))}




                                </Row>
                            </Container>

                        </div>

                    </Navbar.Collapse>

                </Container>
            </Navbar>
        </div>
    );
}

export default Navbartest;
