import { Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { useContext } from "react";
import { LanguageContext } from "../LanguageContext";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade"
import { Autoplay, Mousewheel } from "swiper";

const Module9 = ({ moduleData }) => {

    const { lang } = useContext(LanguageContext);

    return (
        <div className="threemost-container"  >
            <Container>


                <Row className="d-flex justify-content-center text-white ">

                    <div className="col-lg-8 " data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom">
                        <h1 className="display-2 mb-5 d-flex justify-content-center text-center">
                        {lang === "en" ? "3 most important facts"  : "3 Legfontosabb Tény"}
                            </h1>
                    </div>
                    <Swiper
                        data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom"
                        style={{ maxHeight: "150px" }}
                        direction={"vertical"}
                        slidesPerView={1}
                        spaceBetween={30}
                        // mousewheel={true}
                        loop={true}
                        speed={2000}
                        autoplay={{
                            delay: 2500,
                            pauseOnMouseEnter: true,
                            disableOnInteraction: true,
                            // reverseDirection:true,

                        }}
                        modules={[Mousewheel, Autoplay,]}
                        navigation
                        effect="fade"
                        fadeEffect={{ crossFade: true }}
                        breakpoints={{
                            375: {

                                slidesPerView: 1,

                            }
                        }}
                        className="swiper-main mySwiper text-center "
                    >
                        {moduleData && moduleData.map((fact,index) => (
                            <div key={index}>
                                <SwiperSlide className="swiper-important fs-5 example-fade-text" key={index}>
                                    <div dangerouslySetInnerHTML={{ __html: fact.text }}></div>
                               
                                </SwiperSlide>

                            </div>

                        ))}

                        <SwiperSlide className="swiper-important fs-5 example-fade-text"></SwiperSlide>


                    </Swiper>




                </Row>
            </Container>
        </div>
    );
}

export default Module9;
