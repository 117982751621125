import { Container, Col } from "react-bootstrap";


const Module22 = ({trainingData}) => {


    return (
        <div className="flip-container text-white mt-5 mb-5" data-aos="fade-up"
            data-aos-anchor-placement="top-bottom">

            <Container className=" fw-bold d-flex flex-wrap" >

                {trainingData && trainingData.trainers.map((member) => (

                    <Container key={member.name} className="up-1 col-lg-6 mt-4" data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom">

                        <Col className="col-lg-12">
                            <div className=" flip-card">
                                <div className=" flip-card-inner">
                                    <div className="flip-card-front">
                                        <div className="upcoming3-box container-lg p-4  d-grid shadow p-3 mb-5 rounded text-black " style={{ backgroundImage: `url(${member.image})`,backgroundSize:'cover', backgroundPosition: 'center', height: '558px', maxWidth: '700px' }}>
                                            <p className="fs-4  d-flex align-items-end d-flex justify-content-center"  >{member.name}</p>
                                        </div>
                                    </div>
                                    <div className="flip-card-back">
                                        <div className=" upcoming2-box container-lg p-4 shadow p-3 mb-5 rounded" style={{ maxWidth: '558px', height: '558px' }}>

                                            <p className="yellow fs-4 pt-5">{member.name}</p>

                                            {/* <div dangerouslySetInnerHTML={{ __html: member.text }}>

                                            </div> */}
                                            <div>
                                                {/* <Linkedin className='rounded-circle mt-5 p-3' style={{ fontSize: '5rem' }} /> */}
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div></Col>
                    </Container>

                ))

                }
            </Container>
            </div>
    );
}

export default Module22;