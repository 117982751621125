import { Container,Col } from "react-bootstrap";

const Module0 = ({trainingData}) => {
    return ( 
        
        <div className="module-main" style={{backgroundImage:`url(../toppic.png)`}}>
        <Container className="">
            <div className="display-2 text-white pt-3" dangerouslySetInnerHTML={{ __html: trainingData.name }}></div>
            <div className="mt-5 col-lg-7">
                        </div>
            <Col className="mt-5">
                        <img src="../Group.png" alt="" />
                        </Col>
        </Container>
                </div>
     );
}
 
export default Module0;