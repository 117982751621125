
import { Container, Row} from "react-bootstrap";
import { useContext } from "react";
import { LanguageContext } from "../LanguageContext";

const Module11 = ({moduleData}) => {

    const { lang } = useContext(LanguageContext);


    return ( 
        <div className="history-container text-white">
        <Container className="d-flex flex-wrap">
            <div className="col-lg-7">
                <Row className="mt-5">
                    <div className="col-lg-3" data-aos="fade-up"
        data-aos-anchor-placement="top-bottom">
                        <h1 className="display-2 mb-5">{lang === "en" ? "History"  : "Történet"}</h1>

                    </div>
                </Row >
                <div className="col-lg-9" data-aos="fade-up"
        data-aos-anchor-placement="top-bottom">
            <div className="fs-5" dangerouslySetInnerHTML={{ __html: moduleData[0].text }}></div>
                    <a href={lang === "en" ? "/trainings"  : "/kepzeseink"}>
                    <button className="button-book mt-5 mb-5">{lang === "en" ? "TRAININGS"  : "KÉPZÉSEINK"}</button></a>
                </div>
            </div>
            <div className="col-lg-5">
                <img className="img-fluid" src={moduleData[0].image} alt="" />
            </div>

            
        </Container>
    </div>
     );
}
 
export default Module11;
