import { Container,Col } from "react-bootstrap";
// import groupImg from './../../images/Group.png'
const Module0 = ({contentToLoad}) => {
    return ( 
        
        <div className="module-main" style={{backgroundImage:`url(${contentToLoad.image})`}}>
        <Container className="">
            <div className="display-2 text-white pt-3" dangerouslySetInnerHTML={{ __html: contentToLoad.title }}></div>
            <div className="mt-5 col-lg-7">
            <div className="fs-4 text-white pt-3" dangerouslySetInnerHTML={{ __html: contentToLoad.lead }}></div>
                        </div>
            <Col className="mt-5">
                        <img src="./Group.png" alt="" />
                        
                        </Col>
        </Container>
                </div>
     );
}
 
export default Module0;